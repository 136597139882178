import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Badge from "@mui/material/Badge";
import SvgIcon from "@mui/material/SvgIcon";
import React from "react";
import { defaultStyles } from "react-file-icon";
import "./styles/project-file-structure.css";
import "./styles/project-files.css";

const customStyles = {
  ...defaultStyles,
  pdf: {
    ...defaultStyles.pdf,
    glyphColor: "rgb(240, 240, 240)",
    labelColor: "rgb(217, 54, 48)",
    labelTextColor: "rgb(240, 240, 240)",
    color: "rgb(217, 54, 48)",
  },
  doc: {
    ...defaultStyles.doc,
  },
  docx: {
    ...defaultStyles.docx,
  },
  ai: {
    ...defaultStyles.ai,
  },
  png: {
    ...defaultStyles.png,
    glyphColor: "rgba(185, 130, 42, 1)",
    labelColor: "rgba(185, 130, 42, 1)",
    labelTextColor: "rgb(240, 240, 240)",
    color: "rgb(230, 230, 230)",
    foldColor: "rgba(190, 190, 190, 0.952)",
  },
  jpg: {
    ...defaultStyles.png,
    glyphColor: "rgba(185, 130, 42, 1)",
    labelColor: "rgba(185, 130, 42, 1)",
    labelTextColor: "rgb(240, 240, 240)",
    color: "rgb(230, 230, 230)",
    foldColor: "rgba(190, 190, 190, 0.952)",
  },
  jpeg: {
    ...defaultStyles.png,
    glyphColor: "rgba(185, 130, 42, 1)",
    labelColor: "rgba(185, 130, 42, 1)",
    labelTextColor: "rgb(240, 240, 240)",
    color: "rgb(230, 230, 230)",
    foldColor: "rgba(190, 190, 190, 0.952)",
  },
  bmp: {
    ...defaultStyles.png,
    glyphColor: "rgba(185, 130, 42, 1)",
    labelColor: "rgba(185, 130, 42, 1)",
    labelTextColor: "rgb(240, 240, 240)",
    color: "rgb(230, 230, 230)",
    foldColor: "rgba(190, 190, 190, 0.952)",
  },
  dwg: {
    ...defaultStyles.dwg,
    glyphColor: "rgb(255, 122, 20)",
    labelColor: "rgb(255, 122, 20)",
    labelTextColor: "rgb(240, 240, 240)",
    color: "rgb(235, 235, 235)",
    foldColor: "rgba(190, 190, 190, 0.952)",
  },
  dxt: {
    ...defaultStyles.dwg,
    glyphColor: "rgb(255, 122, 20)",
    labelColor: "rgb(255, 122, 20)",
    labelTextColor: "rgb(240, 240, 240)",
    color: "rgb(235, 235, 235)",
    foldColor: "rgba(190, 190, 190, 0.952)",
  },
  eps: {
    ...defaultStyles.dwg,
    glyphColor: "rgb(255, 122, 20)",
    labelColor: "rgb(255, 122, 20)",
    labelTextColor: "rgb(240, 240, 240)",
    color: "rgb(235, 235, 235)",
    foldColor: "rgba(190, 190, 190, 0.952)",
  },
  xls: {
    ...defaultStyles.xls,
  },
  xlsx: {
    ...defaultStyles.xlsx,
  },
  xlr: {
    color: "rgb(235, 235, 235)",
    glyphColor: "#1A754C",
    labelColor: "#1A754C",
    labelTextColor: "rgb(240, 240, 240)",
    foldColor: "rgba(190, 190, 190, 0.952)",
  },
  missing_unknown: {
    color: "rgb(230, 230, 230)",
    glyphColor: "rgb(38, 139, 255)",
    labelColor: "rgb(38, 139, 255)",
    labelTextColor: "rgb(240, 240, 240)",
    foldColor: "rgba(190, 190, 190, 0.952)",
  },
  unknown: {
    color: "rgb(235, 235, 235)",
    glyphColor: "transparent",
    labelColor: "transparent",
    labelTextColor: "rgb(255, 54, 54)",
    foldColor: "rgba(190, 190, 190, 0.952)",
  },
  required: {
    color: "rgb(235, 235, 235)",
    glyphColor: "rgb(200, 36, 36)",
    labelColor: "rgb(200, 36, 36)",
    labelTextColor: "rgb(235, 235, 235)",
    foldColor: "rgba(190, 190, 190, 0.952)",
  },
};

/*
unknown: {
    color: "rgb(235, 235, 235)",
    glyphColor: "rgb(235, 235, 235)",
    labelColor: "transparent",
    labelTextColor: "transparent",
    foldColor: "rgba(190, 190, 190, 0.952)",
  },

*/

const ProjectStatusFolder = ({ requestStatus }) => {
  const folderInfo = {
    P: ["Pending Review", "rgb(248, 154, 87)", "rgb(117, 49, 0)"],
    C: ["Completed", "rgb(96, 210, 96)", "rgb(0, 101, 5)"],
    IP: ["Incomplete", "rgb(80, 140, 245)", "rgb(0, 48, 131)"],
    I: ["Incomplete", "rgb(80, 140, 245)", "rgb(0, 48, 131)"],
    RV: ["Revisions Needed", "rgb(225, 79, 79)", "rgb(149, 0, 0)"],
    A: ["Archived", "rgb(156, 156, 156)", "rgb(49, 49, 49)"],
  };

  return (
    <div className="project-folder-icon-container">
      <SvgIcon
        component={FolderRoundedIcon}
        className={`folder ${folderInfo[requestStatus][0]}`}
        sx={{
          fill: folderInfo[requestStatus][1],
        }}
        viewBox="3.6 3.6 16.8 16.8"
      />
      <p
        className="project-folder-status"
        style={{
          color: folderInfo[requestStatus][2],
          // backgroundColor: folderInfo[requestStatus][2],
        }}
      >
        {folderInfo[requestStatus][0]}
      </p>
    </div>
  );
};

const ArgusFolder = ({ fileCount }) => {
  return (
    <>
      <div className="admin-folder-icon-container">
        <Badge
          badgeContent={fileCount}
          showZero
          classes={{
            badge: "folder-badge",
            root: "folder-badge-root",
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <SvgIcon
            className="folder admin-folder"
            component={FolderRoundedIcon}
            viewBox="3.6 3.6 16.8 16.8"
          />
        </Badge>
        <SvgIcon className="admin-folder-icon" viewBox="0 0 40 40">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="5px"
            viewBox="4.5 1 35 35"
            height="35px"
          >
            <g>
              <path
                fill="#231F20"
                stroke="#231F20"
                strokeMiterlimit="10"
                d="M19.57,5.94L5.77,33.66l17.34,0.02
        c-4.03-3.25-4.67-9.46-1.95-14.49L28.6,5.94H19.57z"
              />
              <path
                fill="none"
                stroke="#231F20"
                strokeMiterlimit="10"
                d="M30.56,34.06"
              />
              <path
                fill="none"
                stroke="#231F20"
                strokeMiterlimit="10"
                d="M22.95,20.66"
              />
              <path
                fill="none"
                stroke="#231F20"
                strokeMiterlimit="10"
                d="M38.01,21"
              />
              <linearGradient
                id="SVGID_1_"
                gradientUnits="userSpaceOnUse"
                x1="37.4634"
                y1="28.3434"
                x2="24.6532"
                y2="17.5794"
              >
                <stop offset="6.13%" stopColor="#066D94" />
                <stop offset="41.35%" stopColor="#5A93B4" />
                <stop offset="79.66%" stopColor="#92C5DD" />
                <stop offset="97.78%" stopColor="#ABE0F2" />
              </linearGradient>
              <path
                fill="url(#SVGID_1_)"
                d="M29.9,7.3l-7.44,13.36c-0.58,1.19-0.91,2.13-1.02,2.87c-0.3,1.58-0.18,3.11,0.25,4.62
        c0.54,1.57,1.34,2.76,2.38,3.61c1.79,1.65,3.81,2.3,6,2.3c1.45,0,2.87-0.36,4.25-1.23c0.97-0.59,1.82-1.37,2.53-2.37
        c0.76-1.02,1.26-2,1.51-3.3c0.24-1.25,0.26-2.25,0.12-3.32c-0.15-0.97-0.48-1.91-0.96-2.84L29.9,7.3z"
              />
            </g>
          </svg>
        </SvgIcon>
      </div>
    </>
  );
};

const ArgusFileIcon = ({
  color = "rgb(235, 235, 235)",
  extension,
  fold = true,
  foldColor = "rgba(190, 190, 190, 0.952)",
  glyphColor = "rgb(16, 114, 151)",
  gradientColor = "rgb(235, 235, 235)",
  gradientOpacity = 0.25,
  labelColor = "rgb(16, 114, 151)",
  labelTextColor = "rgb(235, 235, 235)",
  radius = "4",
  type,
  uniqueId,
}) => {
  const vb = {
    width: 40,
    height: 48,
  };

  const argusIcon = {
    width: vb.width,
    height: vb.height,
  };

  const foldHeight = {
    height: 12,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 48"
      width="100%"
      style={{
        maxWidth: "100%",
      }}
    >
      <defs>
        <clipPath id={`pageRadius${uniqueId}`}>
          <rect x="0" y="0" rx="4" width="40" height="48" />
        </clipPath>
        <clipPath id={`foldCrop${uniqueId}`}>
          <rect width="40" height="12" transform="rotate(-45, 0, 12)" />
        </clipPath>
        <linearGradient
          x1="100%"
          y1="0%"
          y2="100%"
          id={`pageGradient${uniqueId}`}
        >
          <stop
            stopColor={gradientColor}
            stopOpacity={gradientOpacity}
            offset="0%"
          />
          <stop stopColor={gradientColor} stopOpacity="0" offset="66.67%" />
        </linearGradient>
      </defs>

      <g id="file" clipPath={`url(#pageRadius${uniqueId})`}>
        {fold ? (
          <>
            <path
              d={`M0 0 h ${argusIcon.width - foldHeight.height} L ${
                argusIcon.width + 0
              } ${foldHeight.height} v ${
                argusIcon.height - foldHeight.height
              } H 0 Z`}
              fill={color}
            />
            <path
              d={`M0 0 h ${argusIcon.width - foldHeight.height} L ${
                argusIcon.width + 0
              } ${foldHeight.height} v ${
                argusIcon.height - foldHeight.height
              } H 0 Z`}
              fill={`url(#pageGradient${uniqueId})`}
            />
          </>
        ) : (
          <>
            <rect
              x="0"
              y="0"
              width={argusIcon.width}
              height={argusIcon.height}
              fill={color}
            />
            <rect
              x="0"
              y="0"
              width={argusIcon.width}
              height={argusIcon.height}
              fill={`url(#pageGradient${uniqueId})`}
            />
          </>
        )}
      </g>

      {fold && (
        <g transform={`translate(28 ${foldHeight.height}) rotate(-90)`}>
          <rect
            width={argusIcon.width}
            height={argusIcon.height}
            fill={foldColor}
            rx={radius}
            ry={radius}
            clipPath={`url(#foldCrop${uniqueId})`}
          />
        </g>
      )}

      {extension && (
        <>
          <g id={`label${uniqueId}`}>
            <rect
              fill={labelColor}
              x="0"
              y={argusIcon.height - 14}
              width={argusIcon.width}
              height={14}
              clipPath={`url(#pageRadius${uniqueId})`}
            />
          </g>
          <g id={`labelText${uniqueId}`} transform={`translate(0, 34)`}>
            <text
              x={argusIcon.width / 2}
              y="10"
              fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif"
              fontSize="9"
              fill={labelTextColor}
              textAnchor="middle"
              style={{
                fontWeight: "bold",
                textAlign: "center",
                pointerEvents: "none",
                textTransform:
                  extension === "docx" || extension === "doc"
                    ? "uppercase"
                    : "none",
                userSelect: "none",
              }}
            >
              {extension}
            </text>
          </g>
        </>
      )}

      {/* <image href={argusA} x="15" y="8" height="22" width="18" /> */}
      {type == "admin" && (
        <g>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="5px"
            viewBox="0 0 45 40"
            height="26"
          >
            <g>
              <path
                fill="#231F20"
                stroke="#231F20"
                strokeMiterlimit="10"
                d="M19.57,5.94L5.77,33.66l17.34,0.02
        c-4.03-3.25-4.67-9.46-1.95-14.49L28.6,5.94H19.57z"
              />
              <path
                fill="none"
                stroke="#231F20"
                strokeMiterlimit="10"
                d="M30.56,34.06"
              />
              <path
                fill="none"
                stroke="#231F20"
                strokeMiterlimit="10"
                d="M22.95,20.66"
              />
              <path
                fill="none"
                stroke="#231F20"
                strokeMiterlimit="10"
                d="M38.01,21"
              />
              <linearGradient
                id="SVGID_1_"
                gradientUnits="userSpaceOnUse"
                x1="37.4634"
                y1="28.3434"
                x2="24.6532"
                y2="17.5794"
              >
                <stop offset="6.13%" stopColor="#066D94" />
                <stop offset="41.35%" stopColor="#5A93B4" />
                <stop offset="79.66%" stopColor="#92C5DD" />
                <stop offset="97.78%" stopColor="#ABE0F2" />
              </linearGradient>
              <path
                fill="url(#SVGID_1_)"
                d="M29.9,7.3l-7.44,13.36c-0.58,1.19-0.91,2.13-1.02,2.87c-0.3,1.58-0.18,3.11,0.25,4.62
        c0.54,1.57,1.34,2.76,2.38,3.61c1.79,1.65,3.81,2.3,6,2.3c1.45,0,2.87-0.36,4.25-1.23c0.97-0.59,1.82-1.37,2.53-2.37
        c0.76-1.02,1.26-2,1.51-3.3c0.24-1.25,0.26-2.25,0.12-3.32c-0.15-0.97-0.48-1.91-0.96-2.84L29.9,7.3z"
              />
            </g>
          </svg>
        </g>
      )}
    </svg>
  );
};

const BlankFileIcon = ({
  color = "rgb(235, 235, 235)",
  extension,
  fold = true,
  foldColor = "rgba(190, 190, 190, 0.952)",
  glyphColor = "transparent",
  gradientColor = "rgb(235, 235, 235)",
  gradientOpacity = 0.25,
  labelColor = "transparent",
  labelTextColor = "transparent",
  radius = "4",
  type,
  uniqueId,
}) => {
  const vb = {
    width: 42,
    height: 48,
  };

  const argusIcon = {
    width: vb.width,
    height: vb.height,
  };

  const foldHeight = {
    height: 14,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 42 48"
      width="100%"
      style={{
        maxWidth: "100%",
      }}
    >
      <defs>
        <clipPath id={`pageRadius${uniqueId}`}>
          <rect x="0" y="0" rx="4" width="42" height="48" />
        </clipPath>
        <clipPath id={`foldCrop${uniqueId}`}>
          <rect
            width="42"
            height={foldHeight.height}
            transform={`rotate(-45, 0, ${foldHeight.height})`}
          />
        </clipPath>
        <linearGradient
          x1="100%"
          y1="0%"
          y2="100%"
          id={`pageGradient${uniqueId}`}
        >
          <stop
            stopColor={gradientColor}
            stopOpacity={gradientOpacity}
            offset="0%"
          />
          <stop stopColor={gradientColor} stopOpacity="0" offset="66.67%" />
        </linearGradient>
      </defs>

      <g id="file" clipPath={`url(#pageRadius${uniqueId})`}>
        {fold ? (
          <>
            <path
              d={`M0 0 h ${argusIcon.width - foldHeight.height} L ${
                argusIcon.width + 0
              } ${foldHeight.height} v ${
                argusIcon.height - foldHeight.height
              } H 0 Z`}
              fill={color}
            />
            <path
              d={`M0 0 h ${argusIcon.width - foldHeight.height} L ${
                argusIcon.width + 0
              } ${foldHeight.height} v ${
                argusIcon.height - foldHeight.height
              } H 0 Z`}
              fill={`url(#pageGradient${uniqueId})`}
            />
          </>
        ) : (
          <>
            <rect
              x="0"
              y="0"
              width={argusIcon.width}
              height={argusIcon.height}
              fill={color}
            />
            <rect
              x="0"
              y="0"
              width={argusIcon.width}
              height={argusIcon.height}
              fill={`url(#pageGradient${uniqueId})`}
            />
          </>
        )}
      </g>

      {fold && (
        <g transform={`translate(28 ${foldHeight.height}) rotate(-90)`}>
          <rect
            width={argusIcon.width}
            height={argusIcon.height}
            fill={foldColor}
            rx={radius}
            ry={radius}
            clipPath={`url(#foldCrop${uniqueId})`}
          />
        </g>
      )}

      {extension === "required" && (
        <>
          <g id={`label${uniqueId}`}>
            <rect
              fill={labelColor}
              x="0"
              y={argusIcon.height - 14}
              width={argusIcon.width}
              height={15}
              clipPath={`url(#pageRadius${uniqueId})`}
            />
          </g>
          <g id={`labelText${uniqueId}`}>
            <text
              id="required-extension-text"
              x={argusIcon.width / 2}
              y={argusIcon.height - 4}
              // fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif"
              fontSize="7px"
              fill={"transparent"}
              textAnchor="middle"
              style={{
                fontWeight: 700,
                textAlign: "center",
                pointerEvents: "none",
                textTransform: "lowercase",
                userSelect: "none",
              }}
            >
              {extension}
            </text>
          </g>
        </>
      )}

      {!extension && (
        <>
          <g id={`label${uniqueId}`}>
            <rect
              fill={labelColor}
              x="0"
              y={argusIcon.height - 14}
              width={argusIcon.width}
              height={14}
              clipPath={`url(#pageRadius${uniqueId})`}
            />
          </g>
          <g id={`labelText${uniqueId}`} transform={`translate(0, 34)`}>
            <text
              id="missing-extension-text"
              x={argusIcon.width / 2}
              y="10"
              fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif"
              fontSize="7"
              fill={labelTextColor}
              textAnchor="middle"
              style={{
                fontWeight: "bold",
                textAlign: "center",
                pointerEvents: "none",
                textTransform:
                  extension === "docx" || extension === "doc"
                    ? "uppercase"
                    : "none",
                userSelect: "none",
              }}
            ></text>
          </g>
        </>
      )}
      {extension === "required" && (
        <g>
          <SvgIcon
            className="required-file-svg"
            id="required-file-svg"
            color={glyphColor}
            htmlColor={glyphColor}
            viewBox="0 2 9 21"
            fontSize="small"
            style={{
              transform: "scale(0.75) !important",
            }}
            sx={{
              fontSize: "10px",
              fill: glyphColor,
              height: "15px",
              width: "15px",
            }}
            inheritViewBox={false}
            classes={{
              root: "required-file-svg-root",
              fontSizeSmall: "required-file-svg-fontSizeSmall",
            }}
          >
            <WarningRoundedIcon
              className="required-file-icon"
              id="required-file-icon"
              classes={{
                root: "required-file-icon-root",
                fontSizeSmall: "required-file-icon-fontSizeSmall",
              }}
              fontSize="small"
              style={{
                transform: "scale(0.75) !important",
              }}
              sx={{
                fontSize: "10px",
                fill: glyphColor,
                height: "15px",
                width: "12px",
                scale: "75% !important",
                transform: "scale(0.75) !important",
              }}
            />
          </SvgIcon>
        </g>
      )}
      {/* {extension !== "required" && (
        <g id={`icon-${uniqueId}`}>
          <SvgIcon
            className="unknown-file-svg"
            id="unknown-file-svg"
            classes={{
              root: "unknown-file-svg-root",
              fontSizeSmall: "unknown-file-svg-fontSizeSmall",
            }}
            fontSize="small"
            sx={{
              display: "block !important",
              color: glyphColor,
              fontSize: "0.5rem !important",
            }}
            inheritViewBox={false}
            viewBox="0 1.5 13 21"
          >
            <FileUploadRoundedIcon
              className="unknown-file-icon"
              id="unknown-file-icon"
              classes={{
                root: "unknown-file-icon-root",
                fontSizeSmall: "unknown-file-icon-fontSizeSmall",
              }}
              fontSize="small"
              sx={{
                display: "block !important",
                color: glyphColor,
                padding: "0 !important",
                margin: "0 !important",
                fontSize: "0.5rem !important",
              }}
              // inheritViewBox={true}
            />
          </SvgIcon>
        </g>
      )} */}
    </svg>
  );
};

export {
  ArgusFileIcon,
  ArgusFolder,
  BlankFileIcon,
  ProjectStatusFolder,
  customStyles,
};
