import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Checkbox from "@mui/material/Checkbox";
import SvgIcon from "@mui/material/SvgIcon";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import useAdminRows from "../../Admin/AdminQueries/useAdminRows";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";
import { BlankFileIcon, customStyles } from "./ArgusCustomIcon";
import "./styles/project-files.css";

// Component to display non-uploaded (admin only) or required files for a project
const RequiredFiles = ({ isAdmin, projectId }) => {
  const missingFileRefs = useRef({});
  const newFileRefs = useRef({});
  const [openSettings, setOpenSettings] = useState(false);
  const [openCheckbox, setOpenCheckbox] = useState(false);
  const [requireFiles, setRequireFiles] = useState({
    required: [],
    notRequired: [],
  });
  const [selectedFile, setSelectedFile] = useState({
    id: "",
    name: "",
    file: "",
    required: false,
  });
  const {
    clientId,
    setOpenSnackbar,
    setSnackbarSeverity,
    setSnackbarMessage,
    queryClient,
  } = useContext(DashboardContext);

  // Query to get the required files for a project
  const { data, isFetched } = useQuery({
    queryKey: ["file-structure", "project", projectId, "required"],
    queryFn: async () => {
      const res = await axios.get(
        `/api/file-structure/project/${projectId}/required/`,
        {
          withCredentials: true,
        },
      );
      return res.data;
    },
    enabled: !!projectId,
  });

  // Query to get the admin rows
  const { data: adminRows, isFetched: adminFetched } = useAdminRows(isAdmin);

  // Mutation to notify the client of updated required files
  const notifyClient = useMutation({
    mutationFn: async () => {
      try {
        const res = await axios.post(
          `/api/client-notification/${clientId}/${projectId}/`,
          { location: "required-files" },
          {
            withCredentials: true,
          },
        );

        return res.data;
      } catch (err) {
        console.log("NOTIFY CLIENT ERR: ", err);
      }
    },
    onError: (err) => {
      setSnackbarSeverity(() => "error");
      setSnackbarMessage(() => "Error notifying submitter!");
      setOpenSnackbar(true);
    },
    onSuccess: (data) => {
      setSnackbarSeverity(() => "success");
      setSnackbarMessage(
        () =>
          "The submitter of this request has been notified of the updated required files",
      );
      setOpenSnackbar(true);
    },
  });

  // Mutation to send the required files to the backend
  const sendRequireFiles = useMutation({
    mutationFn: async () => {
      try {
        const res = await axios.patch(
          `/api/require-files/${projectId}/`,
          {
            files: requireFiles,
          },
          {
            withCredentials: true,
          },
        );

        return res.data;
      } catch (err) {
        console.log("SEND REQUIRE FILES ERR: ", err);
      }
    },
    onSuccess: async (data) => {
      setSnackbarSeverity(() => "success");
      setSnackbarMessage(
        () => "Multiple files requirement status successfully updated",
      );
      setOpenSnackbar(true);
      setOpenCheckbox(false);
      if (data.newRequiredFiles > 0 && adminFetched) {
        const currentRow = adminRows.find((row) => row.id === projectId);
        if (
          currentRow.requestStatus !== "I" &&
          currentRow.requestStatus !== "IP"
        ) {
          await notifyClient.mutateAsync();
        }
      }
    },
    onError: (err) => {
      setSnackbarSeverity(() => "error");
      setSnackbarMessage(() => "Error requiring files! Please try again!");
      setOpenSnackbar(true);
    },
    onSettled: () => {
      queryClient.invalidateQueries(["file-structure", "project", projectId]);
    },
  });

  // Update File Mutation
  const updateFileMutation = useMutation({
    mutationFn: async (data) => {
      const { fileId, file, projectId, required } = data;

      const formData = new FormData();

      if (file) {
        formData.append("file", file);
        formData.append("required", required);
        formData.append("adminUpload", false);
      } else if (!file) {
        formData.append("required", required);
      }

      const res = await axios.patch(
        `/api/file-structure/update/${projectId}/${fileId}/`,
        formData,
        {
          withCredentials: true,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      return res.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries(["file-structure", "project", projectId]);
    },
    onError: (err) => {
      setSnackbarSeverity(() => "error");
      setSnackbarMessage(() => "File update failed");
      setOpenSnackbar(true);
    },
    onSuccess: (data) => {
      setSelectedFile({
        id: "",
        name: "",
        file: "",
        required: false,
      });
      setOpenSettings(false);
      setSnackbarSeverity(() => "success");
      setSnackbarMessage(() => "File successfully updated");
      setOpenSnackbar(true);
    },
  });

  // Create a ref for each file icon to be able to trigger the file input
  useEffect(() => {
    if (isFetched && data?.files.length > 0) {
      console.log("Files: ", data?.files);
      data.files.forEach((file) => {
        if (file.name) {
          missingFileRefs.current[file.name] = createRef();
        }
        if (file.id) {
          newFileRefs.current[file.id] = createRef();
        }
      });
    }
  }, [isFetched, data]);

  // Function to trigger the file input
  const handleOpenFileInput = (e) => {
    e.preventDefault();
    console.log("Ref on click: ", newFileRefs.current[selectedFile.id].current);
    if (newFileRefs.current[selectedFile.id].current) {
      newFileRefs.current[selectedFile.id].current.click();
    } else {
      console.log("No file input selected");
    }
  };

  const handleUploadFile = async (e) => {
    const file = e.target.files[0];
    setSelectedFile((prev) => ({ ...prev, file: file }));

    const fileInfo = {
      fileId: selectedFile.id,
      file: file,
      projectId: projectId,
      required: selectedFile.required,
    };

    await updateFileMutation.mutateAsync(fileInfo);
  };

  // Update the file requirement status
  useEffect(() => {
    if (isFetched && data?.files.length > 0) {
      let notRequiredFiles = [];
      let requiredFiles = [];
      data.files.forEach((file) => {
        if (!file.required) {
          notRequiredFiles.push(file.id);
        } else {
          requiredFiles.push(file.id);
        }
      });
      setRequireFiles({
        required: requiredFiles,
        notRequired: notRequiredFiles,
      });
    }
  }, [isFetched, data]);

  // Set the active file when openSettings is true
  useEffect(() => {
    if (openSettings && selectedFile.id && selectedFile.name) {
      const currentFile = document.getElementById(
        `${selectedFile.name}-${selectedFile.id}`,
      );

      if (currentFile && !currentFile.classList.contains("active-file")) {
        // add active class to the selected file
        currentFile.classList.add("active-file");
      }
    } else {
      // find the active file and remove the active class
      const activeFiles = document.getElementsByClassName("active-file");
      if (activeFiles.length > 0) {
        for (let i = 0; i < activeFiles.length; i++) {
          activeFiles[i].classList.remove("active-file");
        }
      }
    }
  }, [openSettings, selectedFile]);

  return (
    <div className="file-type-container" id="missing-files-container">
      <div
        className="file-settings-container"
        style={{
          visibility: openSettings ? "visible" : "hidden",
        }}
      >
        <ButtonGroup
          className="missing-file-button-group"
          variant="contained"
          aria-label="Missing File Button Group"
          classes={{ root: "missing-file-group-root" }}
        >
          <Button
            className="file-settings-button update-file-button"
            id="update-file-button"
            onClick={handleOpenFileInput}
          >
            Upload
          </Button>
          <input
            type={"file"}
            name={`file-${selectedFile.name}`}
            id={`${selectedFile.name}-file`}
            className={`file-upload-input ${
              selectedFile.name ? `${selectedFile.name}-input` : ""
            }`}
            onChange={handleUploadFile}
            ref={newFileRefs.current[selectedFile.id]}
            style={{
              display: "none",
            }}
          />
          {isAdmin && (
            <Button
              className="file-settings-button require-file-button"
              id="require-file-button"
              onClick={async (e) => {
                const newFileData = {
                  ...selectedFile,
                  required: !selectedFile.required,
                };
                setSelectedFile(newFileData);

                await updateFileMutation.mutateAsync({
                  fileId: selectedFile.id,
                  file: null,
                  projectId: projectId,
                  required: !selectedFile.required,
                });
              }}
            >
              {selectedFile.required ? "Unrequire" : "Require"}
            </Button>
          )}
        </ButtonGroup>
      </div>
      {isAdmin && (
        <>
          <Button
            className={`require-files-button ${openCheckbox ? "close" : ""}`}
            id="require-files-button"
            variant="contained"
            onClick={() => {
              setOpenCheckbox((prev) => !prev);
            }}
          >
            {openCheckbox ? "Close" : "Require Files"}
          </Button>
          {openCheckbox && (
            <Button
              className="submit-required-files-button"
              id="submit-required-files-button"
              variant="contained"
              onClick={async () => {
                console.log("REQUIRE FILES: ", requireFiles);
                await sendRequireFiles.mutateAsync();
              }}
            >
              Submit
            </Button>
          )}
        </>
      )}
      <div className="files-container">
        {isFetched && data?.files.length > 0
          ? data.files.map((file) => {
              if (!isAdmin && !file.required) {
                return null;
              }
              return (
                <div
                  key={`${file.name}-${file.id}`}
                  className={`file-container missing ${
                    file.required
                      ? "required-file"
                      : openCheckbox
                        ? "require-file"
                        : ""
                  }`}
                  id={`${file.name}-${file.id}`}
                  ref={missingFileRefs.current[file.name]}
                  onClick={(e) => {
                    // e.preventDefault();
                    if (openCheckbox) return;
                    console.log("FILE CLICKED: ", file);
                    const activeFiles =
                      document.getElementsByClassName("active-file");
                    if (activeFiles.length > 0) {
                      for (let i = 0; i < activeFiles.length; i++) {
                        activeFiles[i].classList.remove("active-file");
                      }
                    }
                    e.currentTarget.classList.add("active-file");
                    const fileInfo = {
                      id: file.id,
                      name: file.name,
                      file: "",
                      required: file.required,
                    };
                    setSelectedFile(() => fileInfo);
                    setOpenSettings(() => true);
                  }}
                >
                  {openCheckbox && (
                    <div className="require-file-checkbox-container">
                      <Checkbox
                        className="require-file-checkbox"
                        id={"long-class-name"}
                        value={file.id}
                        checked={requireFiles.required.includes(file.id)}
                        onChange={(e) => {
                          let file_id = +e.target.value;
                          let checked = e.target.checked;

                          if (checked) {
                            if (!requireFiles.required.includes(file_id)) {
                              let newRequireFiles = [
                                ...requireFiles.required,
                                file_id,
                              ];
                              let newNotRequireFiles =
                                requireFiles.notRequired.filter(
                                  (id) => id !== file_id,
                                );
                              setRequireFiles({
                                required: newRequireFiles,
                                notRequired: newNotRequireFiles,
                              });
                            }
                          } else {
                            if (!requireFiles.notRequired.includes(file_id)) {
                              let newRequireFiles =
                                requireFiles.required.filter(
                                  (id) => id !== file_id,
                                );
                              let newNotRequireFiles = [
                                ...requireFiles.notRequired,
                                file_id,
                              ];
                              setRequireFiles({
                                required: newRequireFiles,
                                notRequired: newNotRequireFiles,
                              });
                            }
                          }
                        }}
                      />
                    </div>
                  )}
                  <div className="file-icon-container">
                    <SvgIcon className="file-icon" inheritViewBox>
                      <BlankFileIcon
                        extension={`${file.required ? "required" : ""}`}
                        uniqueId={`${file.name}_${file.id}`}
                        {...customStyles[
                          `${file.required ? "required" : "unknown"}`
                        ]}
                      />
                    </SvgIcon>
                    <p
                      className={`file-name missing-text ${
                        file.required ? "required-file-text" : ""
                      }`}
                    >
                      {file.name !== "endangeredSpeciesReport"
                        ? file.displayName
                        : "Endangered Species Report"}
                    </p>
                  </div>
                </div>
              );
            })
          : null}
      </div>
      {selectedFile && openSettings && (
        <ClickAwayListener
          onClickAway={(e) => {
            if (
              openSettings == false ||
              e.target.id === `${selectedFile.name}-file` ||
              e.target.className.includes("file-settings-button") ||
              e.target.className.includes("uploaded-file-button-group")
            ) {
              return;
            }
            if (!e.target.className.includes("active-file")) {
              setOpenSettings(() => false);
              setSelectedFile(() => ({
                id: "",
                name: "",
                url: "",
              }));
            }
          }}
        >
          <div className="click-away-area" />
        </ClickAwayListener>
      )}
    </div>
  );
};

export default RequiredFiles;
