import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useContext, useRef, useState } from "react";
import { InfoCircle, Upload } from "react-bootstrap-icons";
import "../../../../Tooltip/styles/tooltip.css";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import "./styles/create-file-popover.css";

// Create a new file popover
const CreateFilePopover = ({ open, setOpen, projectId, queryClient }) => {
  const { isAdmin } = useContext(DashboardContext);
  const [newFileState, setNewFileState] = useState({
    name: "",
    fileStatus: "AT",
    file: null,
  });
  const [existingFileState, setExistingFileState] = useState({
    fileId: "",
    file: null,
    required: false,
  });
  const newFileRef = useRef(null);
  const existingFileRef = useRef(null);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [showNameInput, setShowNameInput] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");

  const { data: missingData, isFetched: missingDataFetched } = useQuery({
    queryKey: ["file-structure", "project", projectId, "required"],
    queryFn: async () => {
      const res = await axios.get(
        `/api/file-structure/project/${projectId}/required/`,
        {
          withCredentials: true,
        },
      );
      return res.data;
    },
    enabled: !!projectId,
  });

  // create a function that takes a string ("Dust Permit"), and returns the string in camel case ("dustPermit")
  const camelCase = (str) => {
    if (str.split(" ").length === 1) return str.toLowerCase();
    return str
      .split(" ")
      .map((word, idx) => {
        if (idx === 0) {
          return word.toLowerCase();
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join("");
  };

  // Mutation to update an existing file
  const { mutateAsync: updateExistingFile } = useMutation({
    queryKey: ["file-structure", "project", projectId],
    mutationFn: async () => {
      const formData = new FormData();
      formData.append("file", existingFileState.file);
      formData.append("required", existingFileState.required);
      formData.append("adminUpload", false);

      const res = await axios.patch(
        `/api/file-structure/update/${projectId}/${existingFileState.fileId}/`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      return res.data;
    },
    onSuccess: () => {
      setExistingFileState({
        name: "",
        fileStatus: "AT",
        file: null,
      });
      setSelectedFile("");
      queryClient.invalidateQueries(["file-structure", "project", projectId]);
    },
    onError: (err) => {
      console.log("UPDATE FILE ERR: ", err);
    },
  });

  // Mutation to add a new file
  const { mutateAsync: addNewFile } = useMutation({
    queryKey: ["file-structure", "project", projectId],
    mutationFn: async () => {
      const formData = new FormData();
      formData.append("file", newFileState.file);
      formData.append("fileName", newFileState.file.name);
      formData.append("name", camelCase(newFileState.name));
      formData.append("displayName", newFileState.name);
      formData.append("fileStatus", newFileState.fileStatus);
      formData.append("adminUpload", isAdmin);

      const res = await axios.post(
        `/api/file-structure/new/${projectId}/`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      return res.data;
    },
    onSuccess: () => {
      setNewFileState({
        name: "",
        fileStatus: "AT",
        file: null,
      });
      queryClient.invalidateQueries(["file-structure", "project", projectId]);
    },
    onError: (err) => {
      console.log("ADD FILE ERR: ", err);
    },
  });

  const handleClose = () => {
    setOpen(() => false);
    setNewFileState({
      name: "",
      fileStatus: "AT",
      file: null,
    });
    setExistingFileState({
      fileId: "",
      file: null,
      required: false,
    });
    setSelectedFile("");
    setShowNameInput(false);
  };

  return (
    <Modal id="create-file-modal" open={open} onClose={handleClose}>
      <div
        id="create-file-form"
        style={
          showNameInput
            ? {
                height: "570px",
                maxHeight: "570px",
              }
            : {
                height: "500px",
                maxHeight: "500px",
              }
        }
      >
        <h1 id="create-file-heading">Upload New File</h1>
        <hr id="create-file-hr" />
        <p id="create-file-p">
          {
            "If the file you are looking for is not listed, please select 'Upload New File', and fill out the form below to upload the file."
          }
        </p>
        {missingDataFetched && missingData?.files.length > 0 && (
          <FormControl
            id="missing-files-form"
            classes={{ root: "missing-files-form-root" }}
          >
            <InputLabel
              id="select-missing-files-label"
              classes={{ shrink: "missing-files-label-shrink" }}
              htmlFor="missing-files-select"
            >
              Select File
            </InputLabel>
            <Select
              id="missing-files-select"
              className="missing-files-select"
              variant="standard"
              value={selectedFile}
              SelectDisplayProps={{ className: "select-display" }}
              MenuProps={{
                classes: { paper: "select-paper", list: "select-list" },
              }}
              classes={{
                root: "files-select-root",
                select: "files-select",
                icon: "files-select-icon",
                nativeInput: "files-select-native-input",
              }}
              onChange={(e) => {
                e.preventDefault();
                console.log("SELECT VALUE: ", e.target.value);
                if (e.target.value === 0) {
                  setSelectedFile(() => "");
                  setShowNameInput(false);
                  setNewFileState({
                    name: "",
                    fileStatus: "AT",
                    file: null,
                  });
                  setExistingFileState(() => ({
                    fileId: "",
                    file: null,
                    required: false,
                  }));
                } else if (e.target.value === -1) {
                  console.log("UPLOAD NEW FILE");
                  setShowNameInput(() => true);
                  setNewFileState({
                    name: "",
                    fileStatus: "AT",
                    file: null,
                  });
                  setSelectedFile(-1);
                } else {
                  setShowNameInput(() => false);
                  const file = missingData.files.find(
                    (f) => f.id === e.target.value,
                  );
                  setSelectedFile(file.id);
                  setExistingFileState({
                    fileId: file.id,
                    file: null,
                    required: file.required,
                  });
                }
              }}
            >
              <MenuItem
                key="clear-files-select"
                className="files-select-item"
                id="clear-files-item"
                classes={{
                  root: "files-select-item-root",
                  selected: "files-select-item-selected",
                  divider: "files-select-item-divider",
                }}
                value={0}
                divider
              >
                Clear Selection
              </MenuItem>
              <MenuItem
                key="other-files-item"
                className="files-select-item"
                id="other-files-item"
                classes={{
                  root: "files-select-item-root",
                  selected: "files-select-item-selected",
                  divider: "files-select-item-divider",
                }}
                value={-1}
                divider
              >
                Upload New File
              </MenuItem>
              {missingData.files.map((file) => (
                <MenuItem
                  key={file.id}
                  className="files-select-item"
                  classes={{
                    root: "files-select-item-root",
                    selected: "files-select-item-selected",
                    divider: "files-select-item-divider",
                  }}
                  value={file.id}
                  divider
                >
                  {file.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {showNameInput && selectedFile === -1 ? (
          <>
            <div id="file-name-container">
              <label className="new-file-form-label" htmlFor="file-name-form">
                General File Name:{" "}
              </label>
              <div id="file-name-info-container">
                <FormControl
                  className="file-name-form"
                  id="file-name-form"
                  classes={{ root: "file-name-form-root" }}
                  variant="outlined"
                >
                  <InputLabel
                    id="file-name-label"
                    classes={{ shrink: "file-name-label-shrink" }}
                    htmlFor="file-name-input"
                  >
                    File Name (NOI, Contact Sheet, etc.)
                  </InputLabel>
                  <OutlinedInput
                    type="text"
                    id="file-name-input"
                    value={newFileState.name}
                    onChange={(e) =>
                      setNewFileState({ ...newFileState, name: e.target.value })
                    }
                    classes={{
                      root: "file-name-input-root",
                      notchedOutline: "file-name-input-notched-outline",
                      input: "file-name-input-input",
                    }}
                    endAdornment={
                      <Tooltip
                        id="clear-btn-tooltip"
                        classes={{
                          tooltip: "tooltip-clear-btn",
                          popper: "popper-clear-btn",
                          tooltipPlacementTop: "tooltip-top",
                        }}
                        title={newFileState.name ? "Clear Field" : ""}
                        placement="top"
                      >
                        <InputAdornment
                          className="file-name-clear-adornment"
                          id="file-name-adornment"
                          position="end"
                          style={{
                            visibility: newFileState.name
                              ? "visible"
                              : "hidden",
                          }}
                        >
                          <IconButton
                            style={{
                              pointerEvents: newFileState.name
                                ? "auto"
                                : "none",
                            }}
                            aria-label="clear file name"
                            onClick={() =>
                              setNewFileState({ ...newFileState, name: "" })
                            }
                            id="file-name-clear-btn"
                            disabled={newFileState.name ? false : true}
                          >
                            <CloseIcon
                              id="clear-file-icon"
                              className="clear-icon"
                            />
                          </IconButton>
                        </InputAdornment>
                      </Tooltip>
                    }
                  />
                </FormControl>
                <Tooltip
                  id="tf-tooltip"
                  classes={{
                    tooltip: "text-field-tooltip",
                    popper: "text-field-popper",
                    arrow: "text-field-arrow",
                    tooltipPlacementLeft: "text-field-right",
                  }}
                  title={
                    "Enter the general name of the file you are uploading. This will be used to identify the file in the future. For example, if you're uploading a sheet of contacts, the general name should be 'Contact Sheet' or 'Project Contacts', etc."
                  }
                  placement="right"
                  arrow
                  open={openTooltip}
                  sx={{
                    backgroundColor: "rgb(0, 0, 0, 0.8) !important",
                  }}
                >
                  <InfoCircle
                    className="file-info-circ"
                    color="white"
                    size={30}
                    onMouseEnter={() => setOpenTooltip(true)}
                    onMouseLeave={() => setOpenTooltip(false)}
                  />
                </Tooltip>
              </div>
            </div>
            <div id="new-file-container">
              <label className="new-file-form-label" htmlFor="new-file-form">
                Upload File:{" "}
              </label>
              <input
                type="file"
                id="new-file-input"
                onChange={(e) => {
                  e.preventDefault();
                  setNewFileState({
                    ...newFileState,
                    file: e.target.files[0],
                  });
                }}
                disabled={!showNameInput}
                ref={newFileRef}
                style={{ display: "none" }}
              />
              <Button
                className="new-file-btn"
                id="new-file-btn"
                variant="contained"
                onClick={() => {
                  newFileRef.current.click();
                }}
                startIcon={<Upload id="new-file-icon" />}
              >
                Choose a File
              </Button>
              <p
                id="selected-file-name"
                style={{
                  visibility: newFileState.file ? "visible" : "hidden",
                }}
              >
                {newFileState.file ? newFileState.file.name : ""}
              </p>
            </div>
          </>
        ) : !showNameInput && selectedFile !== -1 ? (
          <div id="new-file-container">
            <label className="new-file-form-label" htmlFor="new-file-form">
              Upload File:{" "}
            </label>
            <input
              type="file"
              id="new-file-input"
              key={selectedFile}
              onChange={(e) => {
                e.preventDefault();
                setExistingFileState({
                  ...existingFileState,
                  file: e.target.files[0],
                });
              }}
              ref={existingFileRef}
              disabled={showNameInput}
              style={{ display: "none" }}
            />
            <Button
              className="new-file-btn"
              id="existing-file-btn"
              variant="contained"
              onClick={() => {
                existingFileRef.current.click();
              }}
              disabled={!selectedFile}
              startIcon={<Upload id="new-file-icon" />}
            >
              Choose a File
            </Button>
            <p
              id="selected-file-name"
              style={{
                visibility: existingFileState.file ? "visible" : "hidden",
              }}
            >
              {existingFileState.file ? existingFileState.file.name : ""}
            </p>
          </div>
        ) : null}
        <div id="new-files-btn-container">
          <Button
            className="cancel-new-file-btn"
            id="cancel-new-file-btn"
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="submit-new-file-btn"
            id="submit-new-file-btn"
            variant="contained"
            disabled={
              (showNameInput && (!newFileState.name || !newFileState.file)) ||
              (showNameInput === false && !existingFileState.file)
            }
            onClick={
              (showNameInput && (!newFileState.name || !newFileState.file)) ||
              (showNameInput === false && !existingFileState.file)
                ? () => {}
                : (e) => {
                    e.preventDefault();
                    if (showNameInput) {
                      addNewFile();
                    } else {
                      updateExistingFile();
                    }
                  }
            }
          >
            Upload
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateFilePopover;
