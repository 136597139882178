import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect } from "react";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";
import RequiredFiles from "./MissingFiles";
import UploadedFiles from "./UploadedFiles";
import "./styles/project-file-structure.css";

// Component to display the uploaded or required folders for a project
const FilesFolder = ({ queryType }) => {
  const { clientId, projectId, setHeading, navigate, isAdmin, queryClient } =
    useContext(DashboardContext);

  // Set the heading based on the query type
  useEffect(() => {
    if (queryType === "uploaded") {
      setHeading("Uploaded Files");
    } else if (queryType === "required") {
      setHeading("Required Files");
    } else if (queryType === "all") {
      navigate(`/dashboard/files/${clientId}/${projectId}`);
    }
  }, []);

  return (
    <div className="project-files-container">
      <div className="breadcrumbs-container">
        <Breadcrumbs
          separator="/"
          aria-label="breadcrumb"
          classes={{
            root: "breadcrumbs-root",
            separator: "breadcrumbs-separator",
          }}
        >
          {isAdmin && (
            <Button
              className="breadcrumbs-btn root-link-btn"
              variant="text"
              onClick={() => navigate("/dashboard/files")}
              startIcon={<HomeIcon id="root-folder-icon" />}
            >
              <Typography
                variant="body1"
                className="breadcrumbs-value root-text"
              >
                Clients Folder
              </Typography>
            </Button>
          )}
          {!isAdmin && (
            <Button
              className="breadcrumbs-btn root-link-btn"
              variant="text"
              onClick={() => navigate(`/dashboard/files/${clientId}`)}
              startIcon={<HomeIcon id="root-folder-icon" />}
            >
              <Typography
                variant="body1"
                className="breadcrumbs-value root-text"
              >
                Client Folder
              </Typography>
            </Button>
          )}
          {isAdmin && (
            <Button
              className="breadcrumbs-btn root-link-btn"
              variant="text"
              onClick={() => navigate(`/dashboard/files/${clientId}`)}
            >
              <Typography
                variant="body1"
                className="breadcrumbs-value root-text"
              >
                Client Folder
              </Typography>
            </Button>
          )}
          <Button
            className="breadcrumbs-btn root-link-btn"
            variant="text"
            onClick={() =>
              navigate(`/dashboard/files/${clientId}/${projectId}`)
            }
          >
            <Typography variant="body1" className="breadcrumbs-value root-text">
              Project Folder
            </Typography>
          </Button>
          <Typography
            variant="body1"
            className="breadcrumbs-value current-text"
          >
            {queryType === "uploaded"
              ? "Uploaded Files"
              : queryType === "required"
                ? "Required Files"
                : "Uploaded by Argus"}
          </Typography>
        </Breadcrumbs>
      </div>
      {queryType === "required" && !isAdmin && (
        <p id="missing-files-description">
          {
            "This section contains all the files from the services questionnaire that are required and have not been uploaded. Click to the file icon to upload the required file."
          }
        </p>
      )}
      {queryType === "required" && isAdmin && (
        <p id="missing-files-description">
          {
            "Click on a singular file to upload a file and/or change its requirement status, or click the 'Require Files' button to select multiple files to require at once."
          }
        </p>
      )}
      {queryType === "uploaded" && (
        <p id="missing-files-description">
          {
            "This section contains all the files that have been uploaded for this project. Files uploaded by Argus are marked with the Argus logo. Click on the file icon to view/remove the file or upload a new or add a file from the questionnaire form."
          }
        </p>
      )}
      {queryType === "uploaded" && (
        <UploadedFiles
          projectId={projectId}
          isAdmin={isAdmin}
          queryClient={queryClient}
        />
      )}
      {queryType === "required" && (
        <RequiredFiles
          projectId={projectId}
          isAdmin={isAdmin}
          queryClient={queryClient}
        />
      )}
    </div>
  );
};

export default FilesFolder;
