import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import Badge from "@mui/material/Badge";
import SvgIcon from "@mui/material/SvgIcon";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setClientID } from "../../../../Redux/Actions/actions";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";
import "./styles/project-file-structure.css";

// Admin only clients folder component
const ClientsFolder = () => {
  const { clientId, isAdmin, navigate, setHeading, queryClient } =
    useContext(DashboardContext);

  const [clientList, setClientList] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setHeading("Clients Folder");
  }, []);

  // Redirect to client folder if not admin
  useEffect(() => {
    if (!isAdmin && clientId) {
      navigate(`/dashboard/files/${clientId}`);
    } else if (!isAdmin && !clientId) {
      navigate(`/`);
    }
  }, [isAdmin, clientId]);

  const {
    data: clients,
    isFetched,
    isLoading,
  } = useQuery({
    queryKey: ["file-structure"],
    queryFn: async () => {
      const res = await axios.get(`/api/file-structure/`, {
        withCredentials: true,
      });
      return res.data;
    },
    enabled: !!isAdmin,
  });

  // Sort folders by name
  useEffect(() => {
    if (isFetched && clients) {
      setClientList([...clients].sort((a, b) => a.name.localeCompare(b.name)));
    }
  }, [isFetched]);

  return (
    <div className="project-files-container">
      <div className="folders-container" id="admin-clients-folder-container">
        {isFetched &&
          clientList.map((client) => (
            <div
              className="folder-container"
              key={client.id}
              onClick={async (e) => {
                e.preventDefault();
                dispatch(setClientID(client.id));

                await queryClient.prefetchQuery({
                  queryKey: ["file-structure", "client", client.id],
                  queryFn: async () => {
                    const res = await axios.get(
                      `/api/file-structure/client/${client.id}/`,
                      {
                        withCredentials: true,
                      },
                    );
                    return res.data;
                  },
                });

                navigate(`/dashboard/files/${client.id}`);
              }}
            >
              <Badge
                id="clients-badge"
                badgeContent={client.projects}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                classes={{ badge: "clients-folder-badge" }}
                showZero
              >
                <SvgIcon
                  component={FolderRoundedIcon}
                  className="folder default-folder"
                  viewBox="3.6 3.6 16.8 16.8"
                />
              </Badge>
              <p className="folder-name">
                {client.shortName ? client.shortName : client.name}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ClientsFolder;
