import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { RenderSnackbar } from "../SnackbarAlerts/RenderSnackbar";
import "../Tooltip/styles/tooltip.css";
import {
  fetchToken,
  validAdminUser,
  validClientUser,
} from "../Validation/useVerifyToken";
import DashboardAppBar from "./DashboardComponents/AppBar/DashboardAppBar";
import Sidebar from "./DashboardComponents/Sidebar/Sidebar";
import UserModal from "./DashboardComponents/UserModal/UserModal";
import { DashboardContext } from "./DashboardContext/DashboardContextProvider";
import "./styles/dashboard.css";

// Function to get the search parameters from the URL (?token=...)
const getSearchParams = () => {
  return new URLSearchParams(useLocation().search);
};

const Dashboard = () => {
  const dispatch = useDispatch();

  // Context variables
  const {
    isAdmin,
    client,
    user,
    navigate,
    queryClient,
    openModal,
    setOpenModal,
    setHeading,
    openSnackbar,
    setOpenSnackbar,
    snackbarSeverity,
    setSnackbarSeverity,
    snackbarMessage,
    setSnackbarMessage,
  } = useContext(DashboardContext);

  // Get the token from the URL if it exists
  const searchParams = getSearchParams();
  const token = searchParams.get("token");

  // Verify the token and set the user information in the redux store if the token is exists
  useEffect(() => {
    const tokenValidation = async () => {
      const tokenValidity = await fetchToken(token, navigate);

      // Verify admin token
      if (tokenValidity.token.is_admin) {
        await validAdminUser(
          tokenValidity.data,
          setOpenModal,
          dispatch,
          navigate,
          queryClient,
          setHeading,
        );
        // Verify client token
      } else {
        await validClientUser(
          tokenValidity.data,
          openModal,
          setOpenModal,
          dispatch,
          navigate,
          queryClient,
          setHeading,
        );
      }
    };
    if (token) {
      tokenValidation();
    }
  }, [token]);

  return (
    <div className="main-portal-container" id="main-portal-container">
      {/* Dashboards App/Nav bar (different from App.jsx's Nav bar)  */}
      <DashboardAppBar />
      {/* If the user is not admin or their information isn't stored within the redux persist store, display this modal allowing the user to sign-in or add them self to their companies database */}
      {token === null &&
        isAdmin === false &&
        (user.id === null || user.id === "") && (
          <UserModal
            setOpenSnackbar={setOpenSnackbar}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
          />
        )}
      {/* Basic Success/Error Messages */}
      <RenderSnackbar
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        severity={snackbarSeverity}
        message={
          !snackbarMessage && snackbarSeverity === "success"
            ? "Success!"
            : !snackbarMessage && snackbarSeverity === "error"
              ? "An error occurred!"
              : snackbarMessage
        }
        autoHide={2500}
        anchor={{ vertical: "bottom", horizontal: "center" }}
        type="baseSnackbar"
      />
      <Outlet />
      <Sidebar />
    </div>
  );
};

export default Dashboard;
